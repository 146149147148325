<template>
  <div :class="s.wrapper">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'App',
  components: {
    //ContentsModal,
    //MessagesModal,
    //LoadingsModal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['user', 'modal']),
  },
  created() {
    // ログインチェック
    // this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    // this.$store.dispatch('helper/getMaster');
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" module="s">
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style lang="scss">
//@import './assets/scss/_mq.scss';
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_fonts.scss';
//@import './assets/scss/modal.scss';
@import '@/assets/scss/style.scss';
</style>
