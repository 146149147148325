<template>
  <div :class="s.wrap">
    <div :class="s.firstview">
      <div :class="s.topHeader">
        <h1 :class="s.topHeader__title">
          <img
            :class="s.topHeader__logo"
            src="/img/logos/logo-mark-text-white.svg"
            alt="Mitt">
          <span :class="s.topHeader__bar" />
          <span :class="s.topHeader__text">導入をご検討される事業者様へ</span>
        </h1>
      </div>
      <div :class="s.firstview_container">
        <div :class="s.firstview_inner">
          <div :class="s.message_title">
            <div :class="s.message_title_small">いまも未来も、</div>
            <div :class="s.message_title_lg"><span>交通空白地</span><span>「ゼロ」</span></div>
          </div>
          <div :class="s.firstview_caption">
            <span>設備費や人件費の負担を大幅に軽減する</span>
            <span>人口減少に悩む地方都市タクシー事業を支えるため誕生しました</span>
          </div>
          <div :class="s.firstview_link_container">
            <a :class="s.info_link" href="/form/">
              <div :class="s.info_link_inner">
                <div :class="s.info_link_text">資料・オンライン説明会の申込み</div>
                <span :class="[s.info_link_bar,s.bar_gray]" />
                <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow.svg" alt="">
              </div>
            </a>
            <a :class="s.info_link_mitt" href="https://service.mitt.app/" target="_blank">
              <div :class="s.info_link_inner">
                <div :class="[s.info_link_text,s.info_border_color]">MITTサービスサイトへ</div>
                <span :class="[s.info_link_bar,s.bar_white]" />
                <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow_white.svg" alt="">
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div :class="s.column_gap">
      <div :class="s.container">
        <div :class="s.section_header">
          <h2 :class="s.section_title">FEATURE</h2>
          <div :class="s.section_sub_title">MITTの特徴</div>
        </div>
        <div :class="s.section_caption">
          地方都市ならではの課題を、
          <br>
          現場視点で解決した配車システム
        </div>
        <PartnersFeature />
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.column_gap">
        <div :class="s.section_header">
          <h2 :class="s.section_title">NEWS</h2>
          <div :class="s.section_sub_title">MITTの新着情報</div>
        </div>
        <ul :class="s.news_list">
          <li :class="s.news_item">
            <a :class="s.news_link" href="/news/2">
              <div :class="s.news_item_inner">
                <div :class="s.news_title">持続可能な地域公共交通をめざし、北海道安平町で協定</div>
                <div :class="s.news_info">
                  <div :class="s.news_day">2025.03.07</div>
                  <div :class="s.news_tag">プレスリリース</div>
                </div>
                <div :class="s.news_arrow">
                  <img :class="s.news_arrow_icon" src="/img/partners/news_icon_arrow.svg" alt="arrow">
                </div>
              </div>
            </a>
          </li>
          <li :class="s.news_item">
            <a :class="s.news_link" href="/news/1">
              <div :class="s.news_item_inner">
                <div :class="s.news_title">全国地方都市に先駆けて、群馬県 桐生市でMITTの運用スタート</div>
                <div :class="s.news_info">
                  <div :class="s.news_day">2025.01.31</div>
                  <div :class="s.news_tag">プレスリリース</div>
                </div>
                <div :class="s.news_arrow">
                  <img :class="s.news_arrow_icon" src="/img/partners/news_icon_arrow.svg" alt="arrow">
                </div>
              </div>
            </a>
          </li>
          <li v-if="false" :class="s.news_item">
            <a :class="s.news_link" href="https://service.mitt.app/" target="_blank">
              <div :class="s.news_item_inner">
                <div :class="s.news_title">アプリ配車システムを開発　沼田屋タクシーとシカク　ライドシェア導入も視野に</div>
                <div :class="s.news_info">
                  <div :class="s.news_day">2025.01.31</div>
                  <div :class="s.news_tag">プレスリリース</div>
                </div>
                <div :class="s.news_arrow">
                  <img :class="s.news_arrow_icon" src="/img/partners/news_icon_arrow.svg" alt="arrow">
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div :class="s.case" class="home_case_article">
      <div :class="s.container">
        <div :class="s.section_header">
          <h2 :class="s.section_title">CASE STUDY</h2>
          <div :class="s.section_sub_title">MITTの導入事例</div>
        </div>
      </div>
      <div :class="s.case_article_list_container">
        <div :class="s.case_article_list">
          <ul :class="s.case_article_list_scrollLabel">
            <li
              v-for="item in caseStudyList" :key="item.id"
              :class="[s.case_article_item, item.id === selectedCaseStudy ? '' : s.dark]">
              <button
                @click="selectedCaseStudy = item.id"
                :style="`background-image: url(${item.list_bg})`"
                :class="s.case_article_item_link">
                <div :class="s.case_aricle_item_caption">
                  <span :class="s.case_article_item_number">{{ `0${item.id}`.slice(0, 2) }}</span>
                  <span :class="s.case_aricle_name">{{ item.place }}</span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div :class="s.container">
        <div :class="s.case_article_wrap">
          <div :class="s.case_article_container">
            <div :class="s.case_article_title">{{ caseStudy.title }}</div>
            <div :class="s.case_article_logoItem">
              <img
                v-for="(logo, i) in caseStudy.partners" :key="i"
                :class="s.case_article_logo" :src="logo" :alt="`partner-${i}`">
            </div>
          </div>
          <img :class="s.case_article_visual" :src="caseStudy.thumbnail" alt="">
          <div :class="s.case_article_container" v-html="caseStudy.contents" />
        </div>
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.column_gap_lg">
        <div :class="s.section_header">
          <h2 :class="s.section_title">SOLUTION</h2>
          <div :class="s.section_sub_title">MITTが提供するソリューション</div>
        </div>
        <div :class="s.section_caption">
          利用者視点で変化に対応。
          <br>
          導入から運用まで幅広くカバー。
        </div>
        <ul :class="s.solution_list">
          <li :class="s.solution_item">
            <div :class="s.solution_textBox">
              <div :class="s.solution_itemTitle">MITT配車システム</div>
              <div :class="s.solution_itemCaption">地方に適した配車システム</div>
              <div :class="s.solution_itemDetail">
                MITTは従来の無線配車システム（AVM等）にあたる「オペレーター配車」とアプリから自動で配車ができる「オート(AI)配車」、選べる２つのモードを切り替えられるシステムになっております。
                <br>
                ２つのモードを切り替えることによってより効率的に配車可能となりドライバー不足を補完する形でライドシェアが共存できる仕組みになっております。
              </div>
            </div>
            <div :class="s.solution_imgBox">
              <img :class="s.solution_itemImg" src="/img/partners/solution_item1.png" alt="図１">
            </div>
          </li>
          <li :class="s.solution_item">
            <div :class="s.solution_textBox">
              <div :class="s.solution_itemTitle">配車LINEアプリ</div>
              <div :class="s.solution_itemCaption">LINEアプリからインストールなしで利用可能</div>
              <div :class="s.solution_itemDetail">
                MITTではお客様がスマートフォンのLINEアプリからタクシーの配車注文が可能になります。<br>
                スマートフォンの位置情報を元に配車注文ができ、注文は一度オペレーターを経由するため、<br>
                タクシー配車のバッティングなどが発生せず、他のサービスにない安定的なオペレーター業務との共存が可能です。
              </div>
            </div>
            <div :class="s.solution_imgBox">
              <img :class="s.solution_itemImg" src="/img/partners/solution_item2.png" alt="図2">
            </div>
          </li>
          <li :class="s.solution_item">
            <div :class="s.solution_textBox">
              <div :class="s.solution_itemTitle">デマンド交通機能（標準対応）</div>
              <div :class="s.solution_itemCaption">あらゆるデマンド要件にすべて対応</div>
              <div :class="s.solution_itemDetail">
                道路運送法78条第2号 3号の両方に対応しており、街中から山間部まで同じシステムで運用可能になっています。
                <br>
                フルデマンドからセミデマンドまで各市町村による独自のルールで運用されているデマンド交通にそれぞれに対応できるシステムとなっており、より公共交通を必要としている多くのお客様をカバーします。
              </div>
            </div>
            <div :class="s.solution_imgBox">
              <img :class="s.solution_itemImg" src="/img/partners/solution_item3.png" alt="図3">
            </div>
          </li>
          <!-- <li :class="s.solution_item">
            <div :class="s.solution_textBox">
              <div :class="s.solution_itemTitle">デマンド交通機能（標準対応）</div>
              <div :class="s.solution_itemCaption">未来の公共交通を地元の人が守るライドシェア</div>
              <div :class="s.solution_itemDetail">
                道路運送法第７８条による日本型ライドシェアは都市部を中心に運用が行われていますが、「自家用車活用事業」は地方の公共交通にこそ必要と考えております。
                <br><br>
                デマンド交通にライドシェア（白ナンバー）の活用も可能です。今後さらに進む人口減少下で地元の方が副業としてドライバーをできるため、例えば家業を守りつつ新たな雇用を生みだし公共交通を地元の人で守るようなケースが考えられます。
              </div>
            </div>
            <div :class="s.solution_imgBox">
              <img :class="s.solution_itemImg" src="/img/partners/solution_item4.png" alt="図4">
            </div>
          </li> -->
        </ul>
        <a :class="s.solution_link" href="/form/">
          <div :class="s.solution_arrow">
            <img :class="s.solution_arrow_icon" src="/img/partners/solution_icon_arrow.svg" alt="arrow">
          </div>
          <div :class="s.solution_link_text">
            <div :class="s.solution_link_title">資料・オンライン説明会の申込み</div>
            <div
              :class="s.solution_link_subText">内容の詳細な解説は随時行っています。お気軽にご連絡ください。</div>
          </div>
        </a>
      </div>
    </div>
    <div v-if="false" :class="s.container">
      <div :class="s.column_gap_lg">
        <div :class="s.section_header">
          <h2 :class="s.section_title">
            INTERVIEW
            <br>
            MEDIA
          </h2>
          <div :class="s.section_sub_title">MITTが提供するソリューション</div>
        </div>
        <div :class="s.interview_container">
          <ul :class="s.interview_list">
            <li :class="s.interview_list_item">
              <a :class="s.interview_list_link" href="">
                <img :class="s.interview_item_img" src="/img/partners/interview01_img.jpg" alt="">
                <div :class="s.interview_item_caption">NHKに取り上げられましたNHKに取り上げられましたNHKに取り上げられました</div>
              </a>
            </li>
            <li :class="s.interview_list_item">
              <a :class="s.interview_list_link" href="">
                <img :class="s.interview_item_img" src="/img/partners/interview02_img.jpg" alt="">
                <div :class="s.interview_item_caption">〇〇大学の教授に聞く未来のMITTのあり方</div>
              </a>
            </li>
            <li :class="s.interview_list_item">
              <a :class="s.interview_list_link" href="">
                <img :class="s.interview_item_img" src="/img/partners/interview03_img.jpg" alt="">
                <div :class="s.interview_item_caption">〇〇大学の教授に聞く未来のMITTのあり方</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.column_gap_lg">
        <div :class="s.section_header">
          <h2 :class="s.section_title">
            BUSINESS
            <br>
            PARTNER
          </h2>
          <div :class="s.section_sub_title">MITTに参加する企業・行政</div>
        </div>
        <ul :class="s.partner_list">
          <li :class="s.partner_item">
            <a :class="s.partner_item_link" href="https://www.city.kiryu.lg.jp/" target="_blank">
              <img :class="s.partner_item_img" src="/img/partners/logo_kiryu.png" alt="桐生市">
            </a>
          </li>
          <li :class="s.partner_item">
            <a :class="s.partner_item_link" href="http://www.numataya.co.jp/" target="_blank">
              <img :class="s.partner_item_img" src="/img/partners/partner_item2.png" alt="沼田屋タクシー">
            </a>
          </li>
          <li :class="s.partner_item">
            <a :class="s.partner_item_link" href="https://www.town.abira.lg.jp/" target="_blank">
              <img :class="s.partner_item_img" src="/img/partners/partner_item3.png" alt="北海道安平町">
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.column_gap_lg">
        <div :class="s.section_header">
          <h2 :class="s.section_title">
            COMPANY
            <br>
            INFORMATION
          </h2>
          <div :class="s.section_sub_title">企業情報</div>
        </div>
        <div :class="s.company_container">
          <div :class="s.company_logo">
            <img :class="s.company_logo_img" src="/img/partners/company_logo.png" alt="cicac">
          </div>
          <div :class="s.company_info">
            <p :class="s.company_name">株式会社シカク</p>
            <div :class="s.company_info_caption">
              2017年、東京都渋谷区で発足<br>
              ゼロからシステムを構築することを得意として大手企業のITを担当<br>
              現在では群馬県桐生市に拠点を移転して、地方に本質的に必要なシステムを作るべく活動中<br>
              2024年にMITT（ライドシェア）を桐生市でスタート
            </div>
            <!-- <a :class="s.company_link" href="https://cicac.jp/" target="_blank">
              <div :class="s.company_link_text">会社概要を詳しく見る</div>
              <span :class="[s.info_link_bar,s.bar_white]" />
              <img :class="s.company_link_arrow" src="/img/partners/info_link_arrow_white.svg" alt="">
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <div :class="s.column_gap_lg">
      <div :class="s.guide_container">
        <div :class="s.container">
          <div :class="s.guide_inner">
            <div :class="s.guide_textBox">
              <div :class="s.guide_title">MITTを導入</div>
              <div :class="s.guide_sub_title">事業者さま・市町村さま</div>
              <div :class="s.guide_caption">
                MITTは今と未来の日本における
                <br>
                「交通空白地ゼロ」をめざし開発されました。
                <br>
                地方都市タクシーの配車・ライドシェア・デマンド交通システムになります。
                <br>
                ぜひお気軽にお問合せください。
              </div>
              <a :class="s.guide_info_link" href="/form/">
                <div :class="s.guide_info_link_inner">
                  <div :class="s.guide_info_link_text">資料・オンライン説明会の申込み</div>
                  <span :class="[s.info_link_bar,s.bar_gray,s.guide_smView__bar]" />
                  <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow.svg" alt="">
                </div>
              </a>
            </div>
            <div :class="s.guide_imageBox">
              <img :class="[s.guide_img,s.guide_img_1]" src="/img/partners/guide_img_1.jpg" alt="">
              <img :class="[s.guide_img,s.guide_img_2]" src="/img/partners/guide_img_2.jpg" alt="">
              <img :class="[s.guide_img,s.guide_img_3]" src="/img/partners/guide_img_3.jpg" alt="">
              <img :class="[s.guide_img,s.guide_img_4]" src="/img/partners/guide_img_4.png" alt="">
              <img :class="[s.guide_img,s.guide_img_5]" src="/img/partners/guide_img_5.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="s.footer_wrap">
      <div :class="s.footer_container">
        <div :class="s.message_title">
          <div :class="s.message_title_small">いまも未来も、</div>
          <div :class="s.message_title_lg">交通空白地「ゼロ」</div>
        </div>
        <div :class="s.footer_content">
          <div :class="s.footer_content_item">© 2024 MITT</div>
          <img src="/img/partners/footer_mitt_logo.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PartnersFeature } from '@/components/parts';
import caseStudyList from '@/data/caseStudyList.yaml';

export default {
  name: 'Home',
  components: {
    PartnersFeature,
  },
  data() {
    return {
      activeCase: 0,
      caseStudyList,
      selectedCaseStudy: 2,
    };
  },
  computed: {
    caseStudy() {
      return this.caseStudyList.find((item) => item.id === this.selectedCaseStudy) || this.caseStudyList[0];
    },
  },
  methods: {
    changeActiveCase(num) {
      this.activeCase = num;
    },
  },
};
</script>

<style lang="scss" module="s">
  .container {
    margin: auto;
    width: min(100% - 80px * 2, 1280px);
    @include mdView {
      width: min(100% - 50px * 2, 1280px);;
    }
    @include smView {
      width: max(100% - 20px * 2, 350px);
    }
  }
  .column_gap {
    margin-top: 160px;
    @include smView {
      margin-top: 120px;
    }
  }
  .column_gap_lg {
    margin-top: 200px;
    @include smView {
      margin-top: 120px;
    }
  }
  .section_header {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) ;
    padding-bottom: 72px;
    @include smView {
      padding-bottom: 40px;
    }
  }
  .section_title {
    font-size: 88px;
    font-weight: bold;
    margin-bottom: 29px;
    line-height: 1;
    @include smView {
      font-size: 64px;
      margin-bottom: 24px;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
  .section_sub_title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: rgb(27, 152, 208,);
      border-radius: 50%;
    }
  }
  .section_caption {
    font-size: 75px;
    font-weight: bold;
    padding-top: 48px;

    @include lgView {
      font-size: 65px;
    }

    @include mdView {
      font-size: 55px;
    }

    @include smView {
      font-size: 40px;
      padding-top: 40px;
    }
  }

  .firstview {
    position: relative;
    width: 100%;
    height: 880px;
    background: transparent url('/img/partners/bg_firstview.jpg') center no-repeat;
    background-size: cover;
    @include smView {
      height: 844px;
    }
  }

  .topHeader {
    position: absolute;
    left: 0;
    top: 0;
    padding: 40px;

    @include mdView {
      padding: 32px 20px;
    }
  }

  .topHeader__title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
  }

  .topHeader__logo {
    display: block;
    width: 69px;
    aspect-ratio: 69 / 68;

    @include mdView {
      width: 58px;
    }
  }

  .topHeader__bar {
    display: block;
    width: 2px;
    height: 20px;
    background-color: rgb(255 255 255/0.7);

    @include mdView {
      display: none;
    }
  }

  .topHeader__text {
    font-size: 16px;
    color: rgb(255 255 255/0.8);

    @include mdView {
      display: none;
    }
  }

  .firstview_container {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: min(calc(100% - 80px * 2), 1128px);
    margin: auto;

    @include smView {
      width: max(calc(100% - 30px * 2), 326px);
    }

  }
  .firstview_inner {
    display: flex;
    flex-direction: column;
  }

  .message_title {
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    letter-spacing: 2px;
    @include smView {
      letter-spacing: 0;
    }
  }

  .message_title_small {
    font-size: 76px;
    margin-bottom: 6px;
    @include mdView {
      font-size: 56px;
    }
    @include smView {
      font-size: 46px;
      margin-bottom: 8px;
    }
  }

  .message_title_lg {
    display: flex;
    flex-wrap: wrap;
    font-size: 90px;
    margin-bottom: 32px;

    @include mdView {
      font-size: 70px;
    }

    @include smView {
      font-size: 52px;
      margin-bottom: 18px;
    }
  }

  .firstview_caption {
    font-size: 24px;
    color: white;
    font-weight: 300;
    margin-bottom: 56px;
    letter-spacing: 3px;

    span {
      display: block;
    }

    @include mdView {
      font-size: 18px;
    }

    @include smView {
      font-size: 16px;
      margin-bottom: 48px;

      span {
        display: inline;
      }
    }
  }

  .firstview_link_container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @include smView {
     // flex-direction: column;
    }
  }

  .info_link {
    background-color: white;
    color: black;
    display: flex;
    gap: 12px;
    height: 54px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    width: 347px;
    @include smView {
      height: 57px;
      width: 287px;
    }
  }

  .info_link_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: max(calc(100% - 16px * 2),232px);
    @include smView {
      width: min(calc(100% - 16px * 2),255px);
    }
  }

  .info_link_text {
    font-size: 18px;
    font-weight: bold;
    @include smView {
      font-size: 14px;
    }
  }
  .info_link_bar {
    display: block;
    width: 1px;
    height: 20px;
  }

  .bar_gray {
    background-color:  rgba(0, 0, 0, 0.1);
  }

  .bar_white {
    border-right: 1px solid white;
  }

  .info_link_arrow {
    width: 20px;
  }

  .info_link_mitt {
    color: white;
    border: 1px solid white;
    display: flex;
    gap: 12px;
    height: 54px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    width: 267px;
    @include smView {
     height: 57px;
     width: 226px;
    }
  }

  .news_list {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    padding-top: 72px;
    margin-bottom: 200px;

    @include smView {
      gap: 8px;
      padding-top: 40px;
      margin-bottom: 120px;
    }
  }
  .news_item {
    background-color: rgba(0, 0, 0, 0.1);
    width: calc((100% - 12px * 2 ) / 3);
    border-radius: 8px;

    @include mdView {
      width: calc((100% - 12px ) / 2);
    }

    @include smView {
      width: 100%;
    }
  }
  .news_link {
    width: 100%;
  }
  .news_item_inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 100px 32px;

    @include lgView {
      padding-block: 50px;
    }

    @include smView {
      padding: 32px;
    }
 }
  .news_title {
    font-size: 28px;
    font-weight: bold;

    @include lgView {
      font-size: 25px;
    }

    @include smView {
      font-size: 24px;
    }
  }
  .news_info {
    display: flex;
    font-size: 14px;
    gap: 16px;
  }
  .news_day {
    position: relative;
    padding-right: 16px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 16px;
    }
  }
  .news_arrow {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
  }
  .news_arrow_icon {
    margin: auto;
    width: 24px;
  }
  .case {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding-top: 200px;
    padding-bottom: 200px;
    @include smView {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
  .case_article_list_container {
    height: 200px;
    max-width: 100%;
    margin: auto;
    padding: 0;
    margin-bottom: 72px;
    @include smView {
      height: 112px;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-left: 20px;
    }
  }

  .case_article_list {
    margin-top: 72px;
  }

  .case_article_list_scrollLabel {
    padding-left:  max((100% - 1280px) / 2, 80px);
    padding-right: calc(max((100% - 1280px) / 2, 80px) - 20px);
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    &::after{
      content: "";
      flex-shrink: 0;
      width: 1px;
      height: 1px;
    }
    @include mdView {
      padding-left: 50px;
      padding-right: 50px - 20px;
    }
    @include smView {
      padding: 0;
      padding-right: 20px - 8px;
      gap: 8px;
    }
  }

  .case_article_item {
    border-radius: 8px;
    border: 1px solid white;
    width: 460px;
    height: 200px;
    aspect-ratio: 460 / 200;
    position: relative;
    @include smView {
      width: 216px;
      height: 112px;
      aspect-ratio: 216 / 112;
    }

    &::after {
      border-radius: 8px;
      mix-blend-mode: multiply;
      cursor: pointer;
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgb(0 0 0 / 0.5);
    }

    &.dark {
      position: relative;
      border-color: transparent;
      &::after {
        background-color: rgb(0 0 0 / 0.8);
      }
    }
  }
  .case_article_item_link {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
  }
  .case_article_item_thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    z-index: 0;
  }
  .case_aricle_item_caption {
    position: relative;
    font-weight: bold;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 68px;
    padding-left: 40px;
    color: white;
    @include smView {
      padding-top: 28px;
      padding-left: 24px;
    }
  }
  .case_article_item_number {
    font-size: 36px;
    line-height: 40px;
    @include smView {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .case_aricle_name {
    font-size: 32px;
    white-space: pre;
    @include smView {
      font-size: 20px;
    }
  }

  .case_article_wrap {
    background-color: white;
    color: black;
    margin: 0 auto;
    border-radius: 8px;
  }
  .case_article_container {
    margin: auto;
    width: calc(100% - 160px * 2);
    padding-top: 80px;
    padding-bottom: 80px;

    @include lgView {
      width: calc(100% - 80px * 2);
    }

    @include smView {
      width: calc(100% - 32px * 2);
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .case_article_title {
    font-size: 40px;
    font-weight: bold;
    @include smView {
      font-size: 32px;
    }
  }
  .case_article_logoItem {
    margin: 32px 0;
    display: flex;
    gap: 19px;
    @include smView {
      gap: 13px;
      margin: 16px 0;
    }
  }
  .case_article_logo {
    max-width: 120px;
    max-height: 79px;
    object-fit: contain;
    @include smView {
      max-width: 75px;
      max-height: 58px;
    }
  }
  .case_logo_mitt {
    max-width: 43px;
    @include smView {
      max-width: 34px;
    }
  }
  .case_article_visual {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1280 / 468;
    min-height: 400px;

    @include mdView {
      aspect-ratio: 350 / 232;
    }
  }

  .solution_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 64px;
    @include smView {
      gap: 8px;
      width: min(100%,600px);
      margin: 40px auto 0;
    }
  }
  .solution_item {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: min(80px, 8.333333vw - 40px) min(80px, 8.333333vw - 40px) min(11.111111vw - 40px, 120px);
    display: flex;
    gap: min(76px, 7.361111vw - 30px);
    border-radius: 8px;

    @include mdView {
      //gap: 28px;
    }

    @include smView {
      padding: 48px 24px;
      flex-direction: column;
      gap: 16px;
    }
  }
  .solution_textBox {
    display: flex;
    flex-direction: column;
    width: 524px;
    flex-shrink: 3;
    @include smView {
      width: 100%;
    }
  }
  .solution_itemTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 64px;

    @include lgView {
      margin-bottom: 48px;
    }

    @include mdView {
      margin-bottom: 32px;
    }

    @include smView {
      font-size: 18px;
    }
  }
  .solution_itemCaption {
    font-size: 56px;
    font-weight: bold;
    margin-bottom: 24px;
    @include lgView {
      font-size: 40px;
    }
    @include smView {
      font-size: 32px;
    }
  }
  .solution_itemDetail {
    font-size: 16px;
  }
  .solution_imgBox {
    padding-top: 40px;
    flex-shrink: 4;
    width: 520px;
    @include smView {
      padding: 0;
      width: 100%;
    }
  }
  .solution_itemImg {
    width: 100%;
    border-radius: 8px;
  }
  .solution_link {
    background-color: black;
    width: 100%;
    padding: 77.5px 0;
    display: flex;
    justify-content: center;
    gap: 40px;
    border-radius: 8px;
    margin-top: 16px;
    @include smView {
      padding: 40px 24px;
      gap: 24px;
      align-items: center;
      width: min(100%,600px);
      margin: 8px auto 0;
    }
  }

  .solution_arrow {
    background-color: white;
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 4px;
    flex-shrink: 0;
    @include smView {
      width: 47px;
      height: 47px;
    }
  }
  .solution_arrow_icon {
    margin: auto;
    width: 46.4px;
    @include smView {
      width: 29.2px;
    }
  }
  .solution_link_text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
  }
  .solution_link_title {
    font-size: 40px;
    font-weight: bold;
    @include smView {
      font-size: 24px;
    }
  }
  .solution_link_subText {
    font-size: 16px;
    @include smView {
      font-size: 14px;
    }
  }
  .interview_container {
    padding-top: 72px;
    padding-bottom: 40px;
    @include smView {
      padding-top: 40px;
      padding-bottom: 0;
    }
  }
  .interview_list {
    display: flex;
    gap: 12px;
    @include smView {
      gap: 8px;
      flex-direction: column;
      align-items: center;
    }
  }
  .interview_list_item {
    aspect-ratio: 1 / 1;
    width: 480px;
    position: relative;
    @include smView {
      width: min(100%,480px);
    }
  }
  .interview_list_link {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .interview_item_img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 8px;
  }
  .interview_item_caption {
    position: absolute;
    z-index: 1;
    font-size: 24px;
    font-weight: bold;
    color: white;
    width: calc(100% - 32px * 2);
    left: 32px;
    bottom: 32px;
    @include smView {
      font-size: 20px;
      width: calc(100% - 24px * 2);
      left: 24px;
    }
  }
  .partner_list {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 64px;
    @include smView {
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      gap: 8px;
    }
  }
  .partner_item {
    background-color: rgb(0 0 0 / 0.04);
    border-radius: 8px;
    width: calc((100% - 12px) / 2);
    height: 480px;
    @include smView {
      width: min(100%,630px);
      aspect-ratio: 340 / 240;
      height: auto;
    }
  }
  .partner_item_link {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .partner_item_img {
    margin: auto;
    width: 66%;
    height: 218px;
    object-fit: contain;
    @include smView {
      width: 50%;
      height: 136px;
    }
  }
  .company_container {
    display: flex;
    margin: 64px 0;
    gap: 64px;
    align-items: center;
    @include mdView {
      gap: 30px;
    }
    @include smView {
      gap: 20px;
      flex-direction: column;
    }
  }
  .company_logo {
    border: 1px solid rgba(0, 0, 0, 0.3) ;
    border-radius: 8%;
    width: max((405 / 1280) * 100%, 405px);
    aspect-ratio: 1/1;
    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-shrink: 1;

    @include smView {
      width: min(100%, 405px);
      padding: 0;
    }
  }
  .company_logo_img {
    width: calc((345 / 405) * 100%);
    margin: auto;
  }
  .company_info {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    flex-shrink: 3;

    @include mdView {
    }

    @include smView {
     padding-top: 0;
    }
  }
  .company_name {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 29px;
    @include smView {
      font-size: 20px;
      margin-bottom: 11px;
    }
  }
  .company_info_caption {
    margin-bottom: 64px;
    max-width: 490px;

    @include mdView {
      margin-bottom: 32px;
    }

    @include smView {
     font-size: 14px;
     width: 100%;
     margin-bottom: 24px;
    }
  }
  .company_link {
    background-color: black;
    border-radius: 4px;
    display: flex;
    gap: 12px;
    width: 256px;
    color: white;
    height: 56px;
    align-items: center;
    justify-content: center;
  }

  .company_link_text {
    font-size: 18px;

  }
  .company_link_arrow {
    width: 20px;
  }
  .guide_container {
    background-color: black;
    border-radius: 4px 4px 0 0;
    @include smView {
     width: 100%;
     padding: 120px 32px;
    }
  }

  .guide_inner {
    display: flex;
    justify-content: space-between;
    gap: 66px;
  }

  .guide_textBox {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 196px;
    width: 604px;
    padding-bottom: 266px;
    flex-shrink: 1;
    @include smView {
     width: 100%;
     margin-top: 0;
     padding-bottom: 0;
    }
  }
  .guide_title {
   font-size: 80px;
   font-weight: bold;
   margin-bottom: 32px;
   @include smView {
     font-size: 48px;
     margin-bottom: 16px;
    }
  }
  .guide_sub_title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 48px;
    @include smView {
     font-size: 20px;
     margin-bottom: 40px;
    }
  }
  .guide_caption {
    font-size: 20px;
    margin-bottom: 59px;
    line-height: 180%;
    letter-spacing: 10%;
    @include smView {
     font-size: 16px;
    }
  }
  .guide_info_link {
    background-color: white;
    color: black;
    display: flex;
    gap: 12px;
    height: 68px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 423px;
    @include smView {
     width: 100%;
     gap: 12px;
     height: 84px;
    }
  }
  .guide_info_link_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: calc(100% - 24px * 2);

  }
  .guide_info_link_text {
    font-size: 22px;
    font-weight: bold;
    @include smView {
      font-size: 20px;
    }
  }

  .guide_smView__bar {
    @include smView {
      height: 42px;
    }
  }

  .guide_imageBox {
    width: max(calc(100% - 604px),600px);
    position: relative;
    flex-shrink: 3;

    @include smView {
      display: none;
    }
  }

  .guide_img {
    position: absolute;
    border-radius: 8px;
  }

  .guide_img_1 {
    width: 356px;
    top: 87px;
    left: 375px;
  }

  .guide_img_2 {
    width: 156px;
    top: 453px;
    left: 505px;
  }

  .guide_img_3 {
    width: 106px;
    top: 737px;
    left: 323px;
  }

  .guide_img_4 {
    width: 174px;
    top: 549px;
    left: 0;
  }

  .guide_img_5 {
    width: 227px;
    top: 156px;
    left: 98px;
  }

  .footer_wrap {
    width: 100%;
    background-image: url(/img/partners/footer_img.jpg);
    height: 709px;
    background-size: cover;
    background-position: center;
    display: flex;
    @include smView {
     height: 440px;
    }
  }
  .footer_container {
    position: relative;
    padding-top: 10px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: clamp(66.6666666667%, 79%, 960px);
    @include smView {
      height: 440px;
      width: min(100% - 30px * 2, 326px);
    }
  }

  .footer_content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    width: min(100%, 960px);
    @include smView {
      bottom: 32px;
    }
  }
  .footer_content_item {
    font-size: 16px;
    font-weight: bold;
  }
</style>

<style lang="scss">
.home_case_article {
  .case_article_section {
    margin-bottom: 40px;
    @include smView {
      margin-bottom: 20px;
    }
  }
  .case_article_subtitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
    @include smView {
      font-size: 21px;
    }
  }
  .case_article_caption {
    font-size: 18px;
    line-height: 33px;
    font-weight: 330;
    @include smView {
      font-size: 16px;
    }
  }
  .case_aricle_section_imgConatiner_1 {
    margin-top: 40px;
    margin-bottom: 80px;
    @include smView {
      margin-top: 16px;
      margin-bottom: 40px;
    }
  }
  .case_aricle_section_imgItem {
    display: flex;
    flex-direction: column;
    width: min(100% - 127px - 113px, 560px);
    margin: auto;
    @include smView {
      width: 100%;
    }
  }
  .case_aricle_section_img {
    width: 100%;
    margin-bottom: 12px;
  }
  .case_aricle_section_img_caption {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.64);
    @include smView {
      font-size: 11px;
    }
  }
  .case_aricle_section_imgContainer_2 {
    display: flex;
    gap: 44px;
    margin-top: 20px;
    @include smView {
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
</style>
