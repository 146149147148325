<template>
  <div :class="s.wrap">
    <div :class="s.container">
      <div :class="s.item_lg">
        <img :class="s.item_img" src="/img/partners/feature01.jpg" alt="" />
      </div>
      <div :class="s.container_inner">
        <div :class="[s.item_middle, s.middle_plus]">
          <div :class="s.item_inner">
            <div :class="s.item_LineLogo">
              <img :class="s.item_Line_logo" src="img/partners/line_logo.svg" alt="LINE" />
              <div :class="s.item_Line_text">LINEで呼べる</div>
            </div>
            <div :class="s.item_title">ライドシェア<br>標準対応</div>
            <div :class="s.item_flex_contents">
              <img src="img/QR/line_qr.png" alt="line_qr" :class="s.item_qr">
              <div :class="s.item_caption">お客様が気軽にスマートフォンのLINEアプリからライドシェア・タクシーの配車注文が可能になります。</div>
            </div>
            <!-- <a :class="s.item_link" href="">詳しく見る</a> -->
          </div>
        </div>
        <div :class="s.item_small">
          <img :class="s.item_img" src="/img/partners/feature02.png" alt="">
        </div>
        <div :class="s.item_middle">
          <div :class="s.item_inner">
            <div :class="s.item_header">業界初(当社調べ)</div>
            <div :class="s.item_title">AI音声配車で<br>電話番いらず</div>
            <div :class="s.item_caption">MITT（ミット）では、独自開発した「AI音声配車」で電話での配車依頼にAIが対応。今後さらに負担が増える公共交通の維持をサポート</div>
            <!-- <a :class="s.item_link" href="">詳しく見る</a> -->
          </div>
        </div>
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.item_middle_short">
        <div :class="s.item_inner">
          <div :class="s.item_title">AIデマンド交通<br>フル対応</div>
          <div :class="s.item_caption">道路運送法78条第2号にも対応しており、フルデマンドからセミデマンドまで各市町村独自のデマンド交通要件にそれぞれ対応可能</div>
          <!-- <a :class="s.item_link" href="">詳しく見る</a> -->
        </div>
      </div>
      <div :class="s.item_wide_short">
        <img :class="s.item_img" src="/img/partners/feature03.jpg" alt="">
      </div>
    </div>
    <div :class="s.container">
      <div :class="s.item_small">
        <img :class="s.item_img" src="/img/partners/feature04.png" alt="">
      </div>
      <div :class="s.item_middle">
        <div :class="s.item_inner">
          <div :class="s.item_title">SaaSシステムで<br>低コスト導入・運用</div>
          <div :class="s.item_caption">MITTでは公共交通を守るため、事業者さま・行政さまに寄添ったコンセプトとなっております。<br>他社サービスや無線の維持と比較すると半分以下の低コストで運用できます。（当社調べ）</div>
          <!-- <a :class="s.item_link" href="">詳しく見る</a> -->
        </div>
      </div>
      <div :class="s.item_middle">
        <div :class="s.item_inner">
          <div :class="s.item_title">事務データのDX化</div>
          <div :class="s.item_caption">日々のドライバー日報・事務担当者の入力業務を自動化、運行データを集積して提出書類を自動で作成します。</div>
          <!-- <a :class="s.item_link" href="">詳しく見る</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style module="s" lang="scss">
.wrap {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  margin-top: 64px;

  @include smView {
    gap: 8px;
    margin-top: 40px;
  }
}

.container {
  display: flex;
  width: 100%;
  gap: 12px;

  @include smView {
    gap: 8px;
    flex-direction: column;
  }
}

.item_lg {
  min-height: 754px;
  aspect-ratio: 507/754;
  width: calc((507 / 1280) * 100%);

  @include smView {
    aspect-ratio: 1/1;
    min-height: 350px;
    width: 100%;
  }
}

.item_img {
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
  height: 100%;
}

.container_inner {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @include smView {
    gap: 8px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.item_middle {
  max-width: 507px;
  height: 371px;

  @include smView {
    width: 100%;
    height: auto;
  }
}

.middle_plus {
  max-width: 508px;

  @include smView {
    width: 100%;
  }
}

.item_middle_short {
  max-width: 507px;
  height: 361px;

  @include smView {
    width: 100%;
    height: auto;
  }
}

.item_header {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 8px;
}

.item_wide_short {
  width: 760px;
  height: 361px;

  @include smView {
    width: 100%;
    height: 129px;
  }
}

.item_inner {
  position: relative;
  padding: 48px 40px;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  background-color: black;
  color: white;

  @include smView {
    padding: 40px 32px 60px;
  }
}

.item_small {
  max-width: 242px;
  height: 371px;

  @include smView {
    max-width: 100%;
    height: auto;
  }
}

.item_LineLogo {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.item_Line_logo {
  width: 26px;
  aspect-ratio: 1/1;
}

.item_Line_text {
  color: rgb(40, 180, 56);
  font-size: 18px;
  font-weight: 800;
}

.item_title {
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 24px;

  @include lgView {
    font-size: 36px;
  }

  @include smView {
    font-size: 36px;
  }
}

.item_flex_contents {
  display: flex;
}

.item_qr {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 6px;
}

.item_caption {
  font-size: 16px;
}

.item_link {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 37px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  background-color: #fff;
}

</style>

<script>
export default {
  name: 'partner-feature',
};
</script>
