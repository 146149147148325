<template>
  <div :class="s.topHeader">
    <a :class="s.topHeader__title" href="/">
      <img
        :class="s.topHeader__logo"
        src="/img/logos/logo-mark-text-black.svg"
        alt="Mitt">
      <span :class="s.topHeader__bar" />
      <span :class="s.topHeader__text">導入をご検討される事業者様へ</span>
    </a>
  </div>
  <div :class="s.container">
    <div :class="s.section_header">
      <div :class="s.section_title">CONTACT</div>
      <div :class="s.section_sub_title">資料・オンライン説明会の申込み</div>
    </div>
    <div :class="s.section_bar" />
    <ContactForm />
  </div>
  <div :class="s.footer_wrap">
    <div :class="s.footer_container">
      <div :class="s.message_title">
        <div :class="s.message_title_small">いまも未来も、</div>
        <div :class="s.message_title_lg">交通空白地「ゼロ」</div>
      </div>
      <div :class="s.footer_content">
        <div :class="s.footer_content_item">© 2024 MITT</div>
        <img src="/img/partners/footer_mitt_logo.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { ContactForm } from '@/components/parts';

export default {
  name: 'Form',
  components: {
    ContactForm,
  },
};
</script>

<style lang="scss" module="s">
  .container {
    margin: auto;
    width: min(100% - 80px * 2, 1280px);

    @include mdView {
      width: min(100% - 50px * 2, 1280px);;
    }

    @include smView {
      width: max(100% - 20px * 2, 350px);
    }
  }

  .topHeader {
    padding: 40px;

    @include mdView {
      padding: 32px 20px;
    }
  }

  .topHeader__title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
  }

  .topHeader__logo {
    display: block;
    width: 69px;
    aspect-ratio: 69 / 68;

    @include mdView {
      width: 58px;
    }
  }

  .topHeader__bar {
    display: block;
    width: 2px;
    height: 20px;
    background-color: rgb(255 255 255/0.7);
  }

  .topHeader__text {
    font-size: 16px;
    font-weight: normal;
    //color: rgb(255 255 255/0.8);

    @include mdView {
      display: none;
    }
  }


  .section_header {
    width: 100%;
    padding-bottom: 72px;

    @include smView {
      padding-bottom: 40px;
    }
  }

  .section_title {
    font-size: 88px;
    font-weight: bold;
    margin-bottom: 29px;
    line-height: 1;
    @include smView {
      font-size: 64px;
      margin-bottom: 24px;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
  .section_sub_title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: rgb(27, 152, 208,);
      border-radius: 50%;
    }
  }
  .section_caption {
    font-size: 75px;
    font-weight: bold;
    padding-top: 48px;

    @include lgView {
      font-size: 65px;
    }

    @include mdView {
      font-size: 55px;
    }

    @include smView {
      font-size: 40px;
      padding-top: 40px;
    }
  }

  .section_bar {
    height: 1px;
    width: 100%;
    background-color: rgb(0 0 0 / 0.2);
  }

  .message_title {
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    letter-spacing: 2px;
    @include smView {
      letter-spacing: 0;
    }
  }

  .message_title_small {
    font-size: 76px;
    margin-bottom: 6px;
    @include mdView {
      font-size: 56px;
    }
    @include smView {
      font-size: 46px;
      margin-bottom: 8px;
    }
  }

  .message_title_lg {
    display: flex;
    flex-wrap: wrap;
    font-size: 90px;
    margin-bottom: 32px;

    @include mdView {
      font-size: 70px;
    }

    @include smView {
      font-size: 52px;
      margin-bottom: 18px;
    }
  }

  .footer_wrap {
    margin-top: 200px;
    width: 100%;
    background-image: url(/img/partners/footer_img.jpg);
    height: 709px;
    background-size: cover;
    background-position: center;
    display: flex;

    @include smView {
     height: 440px;
     margin-top: 120px;
    }
  }
  .footer_container {
    position: relative;
    padding-top: 10px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: clamp(66.6666666667%, 79%, 960px);
    @include smView {
      height: 440px;
      width: min(100% - 30px * 2, 326px);
    }
  }

  .footer_content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    width: min(100%, 960px);
    @include smView {
      bottom: 32px;
    }
  }
  .footer_content_item {
    font-size: 16px;
    font-weight: bold;
  }
</style>
