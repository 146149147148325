<template>
  <div :class="s.wrap">
    <div :class="[s.item_box, s.info_box]">
      <div :class="s.info_title">
        MITT 地方都市タクシー配車・ライドシェアアプリ
        <br>
        資料請求・オンライン説明会
      </div>
      <p :class="s.info_text">
        MITTは地方都市タクシー配車・ライドシェア・デマンド交通システムになります。
        <br>
        <br>
        タクシー配車システム（旧AVMシステム等）とライドシェアアプリの両方を導入後すぐに同じ画面でご利用いただけます。
        <br>
        <br>
        また、MITTは地方都市タクシー会社が開発しているため、より実務に沿った仕様となっている他、導入へのハードル（コスト面など）が低くすぐにご利用いただけるような仕様となっております。
        <br>
        <br>
        導入をご検討いただけるタクシー事業者さまはもちろん、市町村ご担当者さまのご連絡をお待ちしております。
        <br>
        <br>
        ぜひこの機会にMITTの導入をご検討くださいませ。
      </p>
    </div>
    <form :class="s.form" @submit.prevent v-if="!isSuccess">
      <div :class="s.item_box">
        <span :class="s.required">希望する項目をお選びください。</span>
        <div v-for="reqOption in requestOptions" :key="reqOption">
          <label>
            <input
              :class="s.check"
              type="radio"
              name="option"
              :value="reqOption"
              v-model="formData.option"
            />
            {{ reqOption }}
          </label>
        </div>
      </div>
      <div :class="s.item_box">
        <span :class="s.required">種別</span>
        <div v-for="option in industryOptions" :key="option">
          <label>
            <input
              :class="s.check"
              type="radio"
              name="Industry"
              :value="option"
              v-model="formData.industry"
            />
            {{ option }}
          </label>
        </div>
      </div>
      <div :class="s.item_box">
        <span :class="s.required">企業名・自治体名</span>
        <input :class="s.answer_column" type="text" placeholder="株式会社〇〇、群馬県桐生市 など" v-model="formData.name" />
      </div>
      <div :class="s.item_box">
        <span :class="s.required">ご住所</span>
        <input :class="s.answer_column" type="text" placeholder="群馬県桐生市〇〇町1-2-3" v-model="formData.address"/>
      </div>
      <div :class="s.item_box">
        <span :class="s.required">お電話番号</span>
        <input :class="s.answer_column" type="text" placeholder="090-xxxx-xxxx" v-model="formData.tel" />
      </div>
      <div :class="s.item_box">
        <span :class="s.required">ご担当者様</span>
        <input :class="s.answer_column" type="text" placeholder="桐生太郎" v-model="formData.staffName" />
      </div>
      <div :class="s.item_box">
        <span :class="s.required">メールアドレス</span>
        <input :class="s.answer_column" type="email" placeholder="sample@mitt.app" v-model="formData.email" />
      </div>
      <button
        :class="[ s.button_submit, isFetching ? s.isFetching : '']"
        @click.prevent="submitContactForm"
        :disabled="!readySubmit"
      >
        <span v-if="isFetching">送信中です...</span>
        <span v-else>送信する</span>
      </button>
    </form>
  </div>
  <div v-if="isSuccess" :class="s.thanks_message">
    お問い合わせありがとうございます。
    <br>
    担当者より順次折り返しの連絡をさせていただきますので
    <br>
    しばらくお待ちくださいますようお願いいたします。
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFetching: false,
      isSuccess: false,
      industryOptions: [
        'タクシー・ハイヤー事業者様',
        'デマンド事業者様',
        '県・市町村ご担当者様',
        'その他',
      ],
      requestOptions: [
        '説明資料請求',
        'オンライン説明会希望',
        'どちらも希望',
      ],
      formData: {
        option: '',
        name: '',
        address: '',
        tel: '',
        staffName: '',
        email: '',
        industry: '',
      },
    };
  },
  computed: {
    readySubmit() {
      return Object.values(this.formData).every((v) => v);
    },
  },
  methods: {
    submitContactForm() {
      if (this.isFetching) return;
      // emailのバリデーション
      if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(this.formData.email)) {
        alert('メールアドレスの形式が正しくありません');
        return;
      }
      this.isFetching = true;
      // 送信処理
      this.axios({
        method: 'POST',
        url: '/v1/contact/inquiries',
        data: this.formData,
      })
        .then((response) => {
          if (response.data.sended) {
            this.isFetching = false;
            this.isSuccess = true;
            this.formData = {
              option: '',
              name: '',
              address: '',
              tel: '',
              staffName: '',
              email: '',
              industry: '',
            };
          } else {
            alert('お問い合わせの送信に失敗しました。お手数ですが、再度お試しください。');
            this.isFetching = false;
          }
        });
    },
  },
};
</script >

<style lang="scss" module="s">
.wrap {
  margin: 60px auto 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-top: 30px;

  @include smView {
    gap: 12px;
  }
}

.item_box {
  color: var(--label-primary);
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 15px;
  width: min(800px, 100%);
  margin: auto;
  padding: 24px;
  background-color: #f6f8fa;
  border-radius: 8px;

  @include smView {
    padding: 18px;
  }
}

.info_box {
  margin: 12px auto;
  gap: 30px;
  background-color: #fff;
  padding: 0;
}

.info_title {
  font-size: 40px;
  font-weight: bold;

  @include smView {
    font-size: 24px;
  }
}

.info_text {
  font-size: 18px;
}

.required {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;

  &::after {
    content: "必須";
    color: #ffffff;
    background-color: rgb(217 48 37);
    font-size: 12px;
    padding: 1px 6px 2px;
    border-radius: 8px;
    margin-top: 1px;
  }
}
.answer_column {
  border-radius: 6px;
  border: 1px solid rgb(192, 190, 190);
  padding: 10px 8px 8px 10px;
}
.button_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 230px;
  margin: 80px auto 0;
  padding: 14px 0 12px;
  border-radius: 4px;
  background-color: #000;
  font-size: 18px;
  appearance: none;
  border: none;
  color: #fff;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.isFetching {
    opacity: 1;
    cursor: progress;
  }

  @include mdView {
    font-size: 20px;
    padding: 14px 64px;
    margin: 40px auto 0;
  }
}
.check {
  margin-right: 5px;
  scale: 1.2;
}

.thanks_message {
  width: fit-content;
  margin-inline: auto;
  margin-top: 40px;
  padding: 80px;
  background-color: #f2f5f5;
  border-radius: 32px;
  font-size: 20px;

  @include smView {
    padding: 25px 18px;
    font-size: 16px;
    border-radius: 18px;
  }
}
</style>
